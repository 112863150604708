<template>
    <div class="action-open-chat">
        <div v-if="chat.agent_id === 6">
            <ActionOpenChatAI :message="message" />
        </div>
        <div v-else-if="chat.agent_id === 7">
            <ActionOpenChatSupport :message="message" />
        </div>
        <div v-else-if="chat.agent_id === 8">
            <!-- agent id 임의값-->
            <ActionOpenChatJudgeManager :message="message" />
        </div>
        <div v-else>
            <ActionOpenChatNormal :message="message" v-if="message.$$friendType === 'normal'" />
            <ActionOpenChatInstant v-else-if="message.$$friendType !== 'normal'" :message="message" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionOpenChat',
    props: ['message'],
    components: {
        ActionOpenChatNormal: () => import('./ActionOpenChatNormal'),
        ActionOpenChatInstant: () => import('./ActionOpenChatInstant'),
        ActionOpenChatAI: () => import('@/routes/chat/chatroom/components/message/message-actions/ActionOpenChatAI'),
        ActionOpenChatSupport: () =>
            import('@/routes/chat/chatroom/components/message/message-actions/ActionOpenChatSupport'),
        ActionOpenChatJudgeManager: () =>
            import('@/routes/chat/chatroom/components/message/message-actions/ActionOpenChatJudgeManager'),
    },
    computed: {
        chat() {
            return this.$store.getters.chat
        },
    },
}
</script>
